.report_card {
    .w-200 {
        width: 200px;
    }
    // margin: 25px;
    .report_header {
        padding: 25px;
        margin-bottom: 25px;
    }
    .report_content {
        padding: 25px;
    }
    .number_label {
        display: inline-block;
        margin-right: 60px;
        position: relative;
        div {
            display: inline-block;
        }
        :first-child {
            font-size: 42px;
            line-height: 80px;
        }
    }
    .report_filter {
        margin-right: 25px;
        :after {
            background: #dce3e6;
            bottom: 0;
            content: "";
            height: 30px;
            left: 100%;
            margin-left: 10px;
            margin-right: 20px;
            position: absolute;
            width: 1px;
        }
    }
    .report_date_picker {
        border-bottom: 1px solid #949494;
        // &:after {
        //     background: #dce3e6;
        //     bottom: 0;
        //     content: "";
        //     height: 30px;
        //     left: 100%;
        //     margin-left: 10px;
        //     margin-right: 20px;
        //     position: absolute;
        //     width: 1px;
        // }
    }
    .right_divider {
        border-right: 1px solid #dce3e6;
        height: 30px;
        margin-left: 10px;
        margin-top: 15px;
    }
}

.filter_Drawer_header {
    border-bottom: 1px solid #eaf0f2;
}
.dashboard_right {
    position: sticky;
    top: 20px;
    left: 20px;
    height: calc(100vh - 104px);
    width: 33%;
    float: right;
}
.dashboard_maincntent {
    width: 66%;
    display: inline-block;
}
.dashboard_card {
    min-height: 400px;
    &:hover {
        .viewmore {
            display: block;
        }
    }
    .viewmore {
        display: none;
    }
}

.dashboard_maincntent {
    .drp_main {
        .MuiSelect-select.MuiSelect-select {
            padding-right: 25px;
            margin: -4px 0px -5px 4px;
        }
        .MuiInput-input.MuiInputBase-input {
            font-size: 15px !important;
        }
    }
}



.report_filter_menu_custom{
    width:250px;
    li{
        list-style-type: none;
    }

}

.crm-all-report-filters{
    position: relative !important;
    .crm-all-report-filters-daterange{
        position: absolute !important;
        top: 60px;
        left: 150px;
    }
    .crm-all-report-filters-daterange-dashboard{
        position: absolute !important;
        top: 60px;
        left: 150px;
    }
}

text.highcharts-credits {
    display: none !important;
}
.crm-all-reports-page-wrapper{
    .materialui-daterange-picker-makeStyles-dateRangeBackdrop-3{
        height: 0;
    }
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    overflow-x: hidden;
}
.empty_goal{
display: inline-block;
    font-size: 60px;
    line-height: 52px;
    color: #FFF;
    background-color: #DCE3E6;
    text-align: center;
    border-radius: 50%;
    width: 65px;
    height: 66px;
    margin-bottom: 10px;
    margin-top: 10px;
}