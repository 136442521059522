$white:#ffffff;
:root {
    --primary:#005E9D;
    --secondary:#ff9e43;
    --yellow:#F2BF58;
    --error:#f44336;
    --green:#08ad6c;
    --bg-default:#fafafa;
    --bg-paper:#fff;
    --text-body:rgba(0, 0, 0, 0.87);
    --text-muted:rgba(0, 0, 0, 0.54);
    --text-hint:rgba(0, 0, 0, 0.38);
    --bg-dark-blue:#1a2038;
    --font: Roboto,"Helvetica Neue",sans-serif;
    --font-th:500 13px/24px var(--font);
    --font-td:400 14px/20px var(--font);
    --font-icon:18px;
    --font-label: 500 14px/14px var(--font) ;
    --font-input: 400 14px/19px var(--font) ;
    --font-caption: 400 12px/20px var(--font);
    --font-body-1: 400 14px/20px var(--font);
    --font-body-2: 500 19px/22px var(--font);
    --font-panel-heading: 500 15px/22px var(--font);
    --font-subheading-1: 400 15px/24px var(--font);
    --font-subheading-2: 400 16px/28px var(--font);
    --font-headline: 400 24px/32px var(--font);
    --font-title: 500 18px/26px var(--font);
    --font-display-1: 400 34px/40px var(--font);
    --font-display-2: 400 45px/48px var(--font);
    --font-display-3: 400 56px/56px var(--font);
    --font-display-4: 300 112px/112px var(--font);
    --elevation-z0: none;
    --elevation-z1: 0px 2px 1px -1px rgba(0, 0, 0, 0.06),0px 1px 1px 0px rgba(0, 0, 0, 0.042),0px 1px 3px 0px rgba(0, 0, 0, 0.036);
    --elevation-z2: 0px 3px 1px -2px rgba(0, 0, 0, 0.06),0px 2px 2px 0px rgba(0, 0, 0, 0.042),0px 1px 5px 0px rgba(0, 0, 0, 0.036);
    --elevation-z3: 0px 3px 3px -2px rgba(0, 0, 0, 0.06),0px 3px 4px 0px rgba(0, 0, 0, 0.042),0px 1px 8px 0px rgba(0, 0, 0, 0.036);
    --elevation-z4: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),0px 4px 5px 0px rgba(0, 0, 0, 0.042),0px 1px 10px 0px rgba(0, 0, 0, 0.036);
    --elevation-z5: 0px 3px 5px -1px rgba(0, 0, 0, 0.06),0px 5px 8px 0px rgba(0, 0, 0, 0.042),0px 1px 14px 0px rgba(0, 0, 0, 0.036);
    --elevation-z6: 0px 3px 5px -1px rgba(0, 0, 0, 0.06),0px 6px 10px 0px rgba(0, 0, 0, 0.042),0px 1px 18px 0px rgba(0, 0, 0, 0.036);
    --elevation-z7: 0px 4px 5px -2px rgba(0, 0, 0, 0.06),0px 7px 10px 1px rgba(0, 0, 0, 0.042),0px 2px 16px 1px rgba(0, 0, 0, 0.036);
    --elevation-z8: 0px 5px 5px -3px rgba(0, 0, 0, 0.06),0px 8px 10px 1px rgba(0, 0, 0, 0.042),0px 3px 14px 2px rgba(0, 0, 0, 0.036);
    --elevation-z9: 0px 5px 6px -3px rgba(0, 0, 0, 0.06),0px 9px 12px 1px rgba(0, 0, 0, 0.042),0px 3px 16px 2px rgba(0, 0, 0, 0.036);
    --elevation-z10: 0px 6px 6px -3px rgba(0, 0, 0, 0.06),0px 10px 14px 1px rgba(0, 0, 0, 0.042),0px 4px 18px 3px rgba(0, 0, 0, 0.036);
    --elevation-z11: 0px 6px 7px -4px rgba(0, 0, 0, 0.06),0px 11px 15px 1px rgba(0, 0, 0, 0.042),0px 4px 20px 3px rgba(0, 0, 0, 0.036);
    --elevation-z12: 0px 7px 8px -4px rgba(0, 0, 0, 0.06),0px 12px 17px 2px rgba(0, 0, 0, 0.042),0px 5px 22px 4px rgba(0, 0, 0, 0.036);
    --elevation-z13: 0px 7px 8px -4px rgba(0, 0, 0, 0.06),0px 13px 19px 2px rgba(0, 0, 0, 0.042),0px 5px 24px 4px rgba(0, 0, 0, 0.036);
    --elevation-z14: 0px 7px 9px -4px rgba(0, 0, 0, 0.06),0px 14px 21px 2px rgba(0, 0, 0, 0.042),0px 5px 26px 4px rgba(0, 0, 0, 0.036);
    --elevation-z15: 0px 8px 9px -5px rgba(0, 0, 0, 0.06),0px 15px 22px 2px rgba(0, 0, 0, 0.042),0px 6px 28px 5px rgba(0, 0, 0, 0.036);
    --elevation-z16: 0px 8px 10px -5px rgba(0, 0, 0, 0.06),0px 16px 24px 2px rgba(0, 0, 0, 0.042),0px 6px 30px 5px rgba(0, 0, 0, 0.036);
    --elevation-z17: 0px 8px 11px -5px rgba(0, 0, 0, 0.06),0px 17px 26px 2px rgba(0, 0, 0, 0.042),0px 6px 32px 5px rgba(0, 0, 0, 0.036);
    --elevation-z18: 0px 9px 11px -5px rgba(0, 0, 0, 0.06),0px 18px 28px 2px rgba(0, 0, 0, 0.042),0px 7px 34px 6px rgba(0, 0, 0, 0.036);
    --elevation-z19: 0px 9px 12px -6px rgba(0, 0, 0, 0.06),0px 19px 29px 2px rgba(0, 0, 0, 0.042),0px 7px 36px 6px rgba(0, 0, 0, 0.036);
    --elevation-z20: 0px 10px 13px -6px rgba(0, 0, 0, 0.06),0px 20px 31px 3px rgba(0, 0, 0, 0.042),0px 8px 38px 7px rgba(0, 0, 0, 0.036);
    --elevation-z21: 0px 10px 13px -6px rgba(0, 0, 0, 0.06),0px 20px 31px 3px rgba(0, 0, 0, 0.042),0px 8px 38px 7px rgba(0, 0, 0, 0.036);
    --elevation-z22: 0px 10px 13px -6px rgba(0, 0, 0, 0.06),0px 20px 31px 3px rgba(0, 0, 0, 0.042),0px 8px 38px 7px rgba(0, 0, 0, 0.036);
    --elevation-z23: 0px 10px 13px -6px rgba(0, 0, 0, 0.06),0px 20px 31px 3px rgba(0, 0, 0, 0.042),0px 8px 38px 7px rgba(0, 0, 0, 0.036);
    --elevation-z24: 0px 10px 13px -6px rgba(0, 0, 0, 0.06),0px 20px 31px 3px rgba(0, 0, 0, 0.042),0px 8px 38px 7px rgba(0, 0, 0, 0.036);
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
html {
    font-size: 16px;
}
*,
*::before,
*::after {
    box-sizing: border-box;
}
a,button,.material-icons {
    transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}
#root, body, html {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}
body {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background-color: var(--bg-default);
    .MuiPaper-rounded{
        box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.06),0px 3px 4px 0px rgba(0, 0, 0, 0.042),0px 1px 8px 0px rgba(0, 0, 0, 0.036); 
        border-radius: 8px; 
    }
    .fab_primary{
        color:#ffffff;
        background-color:var(--primary);
        &:hover{
            background-color: rgb(81, 72, 167);
        }
    }
    .MuiButton-contained{
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.06),0px 8px 10px 1px rgba(0, 0, 0, 0.042),0px 3px 14px 2px rgba(0, 0, 0, 0.036);
        text-transform:none;
        font-weight: 400;
        &.MuiButton-containedPrimary{
            color:#ffffff;
            background-color:var(--primary);
            &:hover{
                background-color: #0081D8;
            }
        }
        &.MuiButton-containedSecondary{
            background-color: var(--secondary);
            color: var(--text-body);
            &:hover{
                background-color: rgb(178, 110, 46);
            }
        }
        &:hover{
            box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.06),0px 8px 10px 1px rgba(0, 0, 0, 0.042),0px 3px 14px 2px rgba(0, 0, 0, 0.036);
        }
    }
    .MuiButton-text{
        text-transform:none;
        font-weight: 400;
        &.MuiButton-textPrimary{
            color: var(--primary); 
        }
    }
    .MuiCheckbox-colorSecondary{
        color: var(--text-muted);
        &.Mui-checked{
         color: var(--secondary);  
         &:hover {
            background-color:rgba(255, 158, 67, 0.08);
        }
        }
        &:hover {
            background-color:rgba(255, 158, 67, 0.08);
        }
    }
    .MuiAutocomplete-option{
        font:var(--font-body-1);
        padding: 3px 16px;
        &:hover{
            background-color: var(--bg-default);
        }
    }
    .MuiInputBase-input {
        font:var(--font-input);
        color: var(--text-body);
        .material-icons{
            vertical-align: middle;
            color: var(--text-muted);
        }
    }
    @mixin placeholder {
        font:var(--font-input);
        color: var(--text-hint);
    }
    .dorpdown_menu{
        display: inline-block;
        padding: 0;
        li{
            padding: 0px 16px;
            .dorpdown_menu_list{
                p{
                    cursor: pointer;
                    font:var(--font-body-1);
                    color: var(--text-body);
                }
            }
            .material-icons{
                color: var(--text-muted);
                font-size: var(--font-icon);
            }
        }
    }
    .MuiInputLabel-animated{
        font: var(--font-label);
        color: var(--text-muted);
    }    
    .MuiPickersClock-clock{
        background-color: var(--bg-default);
    }
    .MuiPickersDay-daySelected,.MuiPickersClockPointer-pointer,.MuiPickersClock-pin,.MuiPickersClockNumber-clockNumberSelected{
        background-color:var(--primary);
    }
    .MuiPickersClockPointer-thumb{
        border-color: var(--primary);
    }
    .MuiTypography-colorPrimary{
        color: var(--primary);
    }
    .MuiMenu-list{
    li{
        color: var(--text-body);
        min-height: auto;
        font:var(--font-body-1);
        &:hover{
            background-color:var(--primary);
            color: $white;
        }
        &.Mui-selected{
            background-color: var(--bg-default);
            color: var(--primary);   
        }
    }
    }
    .dorpdown_menu_list{
        padding: 0;
        margin: 0;
        li{
            padding: 0;
            p{
                cursor: pointer;
                font:var(--font-body-1);
                color: var(--text-body);
            }
        }
        .material-icons{
            color: var(--text-muted);
        }
    }
    .link_primary{
        color: var(--primary);
        font-weight: 500;
        .material-icons{
            color: var(--primary);
            font-size: var(--font-icon);
        }
    }
}
.container {
    padding-left: 16px;
    padding-right: 16px;
    color: var(--text-body);    
}
.position-static {
    position: static;
}
.position-relative {
    position: relative;
}
.position-absolute {
    position: absolute;
}
.position-fixed {
    position: fixed;
}
.position-sticky {
    position: -webkit-sticky;
    position: sticky;
}  
.flex-basis{
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}
.flex-auto {
    flex: auto;
}
.d-inline-block {
    display: inline-block;
}
.d-none {
    display: none;
}
.d-block {
    display: block;
}
.d-flex {
    display: -ms-flexbox!important;
    display: flex!important;
}
.d-inline-flex {
    display: -ms-inline-flexbox;
    display: inline-flex;
}
.flex-row {
    -ms-flex-direction: row;
    flex-direction: row;
}

.flex-column {
    -ms-flex-direction: column;
    flex-direction: column;
}

.flex-row-reverse {
-ms-flex-direction: row-reverse;
flex-direction: row-reverse;
}

.flex-column-reverse {
-ms-flex-direction: column-reverse;
flex-direction: column-reverse;
}

.flex-wrap {
-ms-flex-wrap: wrap;
flex-wrap: wrap;
}

.flex-nowrap {
-ms-flex-wrap: nowrap !important;
flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
-ms-flex-wrap: wrap-reverse;
flex-wrap: wrap-reverse;
}

.flex-fill {
-ms-flex: 1 1 auto;
flex: 1 1 auto;
}

.flex-grow-0 {
-ms-flex-positive: 0;
flex-grow: 0;
}

.flex-grow-1 {
-ms-flex-positive: 1;
flex-grow: 1;
}

.flex-shrink-0 {
-ms-flex-negative: 0;
flex-shrink: 0;
}

.flex-shrink-1 {
-ms-flex-negative: 1;
flex-shrink: 1;
}

.justify-content-start {
-ms-flex-pack: start;
justify-content: flex-start;
}

.justify-content-end {
-ms-flex-pack: end;
justify-content: flex-end;
}

.justify-content-center {
-ms-flex-pack: center;
justify-content: center;
}

.justify-content-between {
-ms-flex-pack: justify;
justify-content: space-between;
}

.justify-content-around {
-ms-flex-pack: distribute;
justify-content: space-around;
}

.align-items-start {
-ms-flex-align: start;
align-items: flex-start;
}

.align-items-end {
-ms-flex-align: end;
align-items: flex-end;
}

.align-items-center {
-ms-flex-align: center;
align-items: center;
}

.align-items-baseline {
-ms-flex-align: baseline;
align-items: baseline;
}

.align-items-stretch {
-ms-flex-align: stretch;
align-items: stretch;
}

.align-content-start {
-ms-flex-line-pack: start;
align-content: flex-start;
}

.align-content-end {
-ms-flex-line-pack: end;
align-content: flex-end;
}

.align-content-center {
-ms-flex-line-pack: center;
align-content: center;
}

.align-content-between {
-ms-flex-line-pack: justify;
align-content: space-between;
}

.align-content-around {
-ms-flex-line-pack: distribute;
align-content: space-around;
}

.align-content-stretch {
-ms-flex-line-pack: stretch;
align-content: stretch;
}

.align-self-auto {
-ms-flex-item-align: auto;
align-self: auto;
}

.align-self-start {
-ms-flex-item-align: start;
align-self: flex-start;
}

.align-self-end {
-ms-flex-item-align: end;
align-self: flex-end;
}

.align-self-center {
-ms-flex-item-align: center;
align-self: center;
}

.align-self-baseline {
-ms-flex-item-align: baseline;
align-self: baseline;
}

.align-self-stretch {
-ms-flex-item-align: stretch;
align-self: stretch;
}
.circular-image-small {
    height: 36px;
    width: 36px;
    border-radius: 50%;
}
.right-16{
    right: 16px !important;
}
.MuiMenu-list{
    .select-menu-input{
        padding: 0;
        margin: 5px 16px;
        .MuiInput-input{
            font:var(--font-input);
        }
        .MuiTouchRipple-root{
            display: none;
        }
        &.Mui-focusVisible{
            background-color: transparent;
        }
        &:hover{
            background-color: transparent;
        }
    }
    .select-menu-item{
        font:var(--font-body-1);
        &.item-with-link{
            border-top: 1px solid #ebedf2;
            padding-top: 10px;
            color: var(--primary);
        }
        &.Mui-selected{           
            background-color: var(--bg-default);
            color: var(--primary);
            .material-icons{
                color: var(--primary);
            }
            &:hover{
                background-color: var(--primary);
                color: $white;
                .material-icons{
                    color: $white;
                }
            }
        }
        &:hover{
            background-color: var(--primary);
            color: $white;
            .material-icons{
                color: $white;
            }
        }
        .material-icons{
            margin-right: 16px;
            color: var(--text-muted);
        }
    }
    .checkbox-item{
        font:var(--font-body-1);
        padding: 3px 16px;
        &:hover{
            background-color: var(--bg-default);
        }
    }
}
.MuiList-dense{
    .MuiListItem-dense {
        font:var(--font-body-1);
        &:hover{
            background-color: var(--primary);
            color: $white;
        }
    }
}
.custom_dialog{
    top: auto !important;
    width: 750px;
    margin: 0 auto;
    .MuiDialog-paperWidthMd{
        max-width: 750px;
        margin-bottom: 0;
        border-radius: 8px 8px 0 0;
        box-shadow: 0 1px 24px 0 rgba(0,0,0,.50);
    }
    .MuiDialog-container{
        height: inherit;
    }
    .MuiDialog-scrollPaper{
        align-items: flex-end;
    }
    .MuiBackdrop-root{
        display: none;
    }
    &.alignitemsend{
        top: calc(100% - 120px)!important;
        max-width: 415px;
        .MuiDialog-paperWidthMd{
            position: absolute;
            top: calc(100% - 88px);
            max-width: 350px;
            .text-white{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }        
        .dialog-body,.dialog-action{
            display: none;
        }
    }
    &.alignitemsend-fullscreen{
        .MuiDialog-paperWidthMd{
            max-width: 350px;
        }
    }
    &.fullscreen{
        top: 0 !important;
        width: auto;
        .MuiDialog-paperWidthMd{
            max-width: 80%; 
            margin-bottom: 0;
            border-radius: 8px;
        }
        .MuiDialog-scrollPaper{
            align-items: center;
        }
        .MuiDialog-container{
            height: 100%;
        }
        .MuiBackdrop-root{
            display: block;
        }
    } 
}
.dialog-body{
    padding: 8px 16px;        
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    flex: 1 1 auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 180px);    
    /*.ant-table-content{
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        max-height: 400px;
    }*/
}
.dialog-action{
    padding: 16px;
}
.custom-checkbox{
    .MuiFormControlLabel-label{
        color: var(--text-muted);
        font: var(--font-label);
    }
}
.conditions-lable{
    font: var(--font-label);
    color: var(--text-body);
}
.custom-select{
    .MuiSelect-selectMenu{
        .material-icons{
            vertical-align: middle;
            color: var(--text-muted);
            font-size: var(--font-icon);
        }
    }

}
.ant-table-wrapper {
    zoom: 1;

    &:before,
    &:after {
        display: table;
        content: '';
    }

    &:after {
        clear: both;
    }

    .ant-spin-nested-loading {
        position: relative;

        .ant-spin-container {
            position: relative;
            -webkit-transition: opacity 0.3s;
            transition: opacity 0.3s;

            &:after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 10;
                display: none \9;
                width: 100%;
                height: 100%;
                background: #fff;
                opacity: 0;
                -webkit-transition: all 0.3s;
                transition: all 0.3s;
                content: '';
                pointer-events: none;
            }

            .ant-table {
                margin: 0;
                padding: 0;
                list-style: none;
                position: relative;
                clear: both;

                .ant-table-body {
                    -webkit-transition: opacity 0.3s;
                    transition: opacity 0.3s;
                    border: 1px solid #ebedf2;
                    border-bottom: 0;
                }

                table {
                    width: 100%;
                    text-align: left;
                    border-radius: 4px 4px 0 0;
                    border-collapse: separate;
                    border-spacing: 0;
                    table-layout: fixed;

                    .ant-table-thead {
                        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
                        position: -webkit-sticky;
                        position: sticky;
                        z-index: 500;
                        top: 0;
                        background-color: #fff;

                        >tr {
                            -webkit-transition: all 0.3s, height 0s;
                            transition: all 0.3s, height 0s;

                            >th {
                                font:var(--font-th);
                                text-align: center;
                                padding: 16.5px 8px;
                                color: #696E6F;
                                -webkit-transition: background 0.3s ease;
                                transition: background 0.3s ease;
                                overflow-wrap: break-word;
                                position: -webkit-sticky;
                                position: sticky;
                                z-index: 500;
                                top: 0;
                                background-color: #fff;
                                box-shadow: 0 1px 0px rgba(0, 0, 0, 0.1);

                                .ant-table-header-column {
                                    display: inline-block;
                                    max-width: 100%;
                                    vertical-align: top;

                                    .ant-table-column-sorters {
                                        display: table-cell;
                                        vertical-align: middle;

                                        >.ant-table-column-title {
                                            display: table-cell;
                                            vertical-align: middle;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .ant-table-tbody {
                        >tr {
                            -webkit-transition: all 0.3s, height 0s;
                            transition: all 0.3s, height 0s;

                            >td {
                                padding: 8px;
                                text-align: center;
                                overflow-wrap: break-word;
                                border-bottom: 1px solid rgba(224, 224, 224, 1);
                                font:var(--font-td);
                                color: var(--text-body);
                                -webkit-transition: all 0.3s, border 0s;
                                transition: all 0.3s, border 0s;

                                .drag-handle {
                                    vertical-align: middle;
                                    display: inline-block;

                                    .material-icons {
                                        color: var(--text-muted);
                                    }
                                }
                            }
                        }

                        .MuiInputLabel-formControl {
                            font:var(--font-input);
                        }

                        .text-left {
                            .MuiInput-formControl {
                                text-align: left;
                                font:var(--font-input);
                                min-width: 80px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.drawer_right{
    inset: 0 0 0 auto !important;
    left: auto !important;
    .MuiBackdrop-root{
        display: none;
    }
    .MuiDrawer-paper{
        box-shadow: var(--elevation-z8);
        top: 60px;
        bottom: 0;
        height: auto;
        width:360px;
        overflow: inherit;
    }
    .frame_body{
        height: calc(100% - 75px);
        .MuiTab-fullWidth{
            min-width: auto;
        }
        .h-100{            
            .MuiBox-root{
                height: 100%;
            }
        }
    }
}
.choose_field_menu{
    .filter_items{
        font:var(--font-input);
        position: relative;
        padding-right: 50px;
        .material-icons{
            color: var(--text-muted);
            &.right_side{
                position: absolute;
                right: 16px;
                color: var(--primary);
            }
        }
        &:hover{
            background-color: var(--primary);
            color:$white;
            .material-icons{
                color:$white !important;
            }
        }
    }
}
.search_bx{
    .MuiInputBase-inputAdornedStart{
        width: 130px;
    }
    .Mui-focused{
        .MuiInputBase-inputAdornedStart{
            width: 200px;
        }
    }
}
.editorClassName {
    &.rdw-editor-main{
        height: 250px;
        border: 1px solid #ebedf2;
        padding:0 10px;
        border-radius: 2px;
        .public-DraftStyleDefault-block{
            margin: 5px 0;
        }
    }
}
.with_editor{
    .rdw-editor-wrapper {
        box-sizing: content-box;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        margin-bottom: 16px;
        .rdw-editor-toolbar{
            width: 100%;
            margin-bottom: 0;
            margin-top:5px;
            .rdw-dropdown-wrapper {
                position: relative;
                ul{
                    position: absolute;
                    top: auto;
                    width: auto;
                    bottom: 30px;
                }
                .rdw-dropdown-carettoopen{
                    border-bottom: 6px solid black;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 0;
                }
                .rdw-dropdown-carettoclose{
                    border-top: 6px solid black;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-bottom: 0;
                }
            }
        }
    }

}
.currency-list-dropdown-autocomplete, .currency-list-dropdown-search
{
    width: 567px;
    justify-content: space-between !important;
    border-bottom: 1px solid var(--text-muted) !important;
    border-radius: 0 !important;
    padding-left: 0 !important;
    
}
.currency-list-selected-currency
{
    float: left;
}
.currency-list-icon {
    float: right;
}
.activityIconColor{
    color: #7467ef !important;
}
.sortTransform{
    transform: rotate(180deg);
}
.position_text{
    white-space: nowrap; 
    width: 190px; 
    overflow: hidden;
    text-overflow: ellipsis;
}
.won_bg{
    background: #dff9d5 !important;
}
.chip_won{
    margin-left: 70% !important;
    border-radius: 0px !important;
    background:#08ad6ccc !important;
    color:white !important;
    text-transform: uppercase !important;
}
.abandoned_bg{
    background: #f3f3f3e8 !important;
}
.chip_abandoned{
    margin-left: 42% !important;
    border-radius: 0px !important;
    background:#e2e2e2cc !important;
    color:white !important;
    text-transform: uppercase !important;
}
.lost_bg{
    background: #ff948ccc !important;
}
.chip_lost{
    margin-left: 69% !important;
    border-radius: 0px !important;
    background:#f44336cc !important;
    color:white !important;
    text-transform: uppercase !important;
}
.won_hr{
    border-top: 1px dashed #08ad6ccc !important;
}
.lost_hr{
    border-top: 1px dashed #f44336cc !important;
}
.abandoned_hr{
    border-top: 1px dashed #e2e2e2cc !important;
}
.won_round{
    background: #08ad6ccc !important;
}
.lost_round{
    background:#f44336cc !important;
}
.abandoned_round{
    background: #e2e2e2cc !important;
}

.plusIconTransform{
    transform: rotate(133deg);
}

.opportunityNameHoverr:hover {
  border-bottom: 2px solid black;
}
.taskNameHoverr:hover {
  border-bottom: 1px solid black;
}

.chip_lost_card{
    margin-left: 76% !important;
    border-radius: 0px !important;
    background:#f44336cc !important;
    color:white !important;
    text-transform: uppercase !important;
}

.chip_abandoned_card{
    margin-left: 55% !important;
    border-radius: 0px !important;
    background:#e2e2e2cc !important;
    color:white !important;
    text-transform: uppercase !important;
}

.chip_won_card{
    margin-left: 77% !important;
    border-radius: 0px !important;
    background:#08ad6ccc !important;
    color:white !important;
    text-transform: uppercase !important;
}

.stop-drag-stage{
    pointer-events: none;
}
.disable-color{
   color: #dadada !important;
}

.related-text{
    font-size: 12px !important ;
    color: rgb(105, 110, 111) !important;
}