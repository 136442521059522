$item-x-padding: 13px;
$navbar-height: 60px;
.navbar {
  position: relative;
  height: $navbar-height;
  box-shadow: $elevation-z8;
  z-index: 98;
  background: var(--bg-dark-blue);
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
  }
  ul.menu {
    max-height: 64px;
    float: left;
    padding-right: 0px;
    z-index: 99;
    width: 100%;
    > li {
      float: left;
      > div {
        > a,
        > div {
          border-bottom: 2px solid;
          height: 48px;
          box-sizing: border-box;
          border-color: transparent;
          margin: 0 6px;
        }
      }
    }
  }
  ul li {
    position: relative;
    margin: 0px;
    ul a {
      padding: 8px $item-x-padding;
      height: 48px;
    }
  }
  a,
  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px $item-x-padding;
    height: $navbar-height;
    font-size: 0.875rem;
    text-decoration: none;
    box-sizing: border-box;
    color: $white;
    .material-icons {
      font-size: 21px;
      margin: 0 4px;
    }
  }

  ul ul {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    background: var(--bg-dark-blue);
    /* has to be the same number as the "line-height" of "nav a" */
    left: $item-x-padding;
    box-shadow: $elevation-z8;
    top: 60px;
    transform: translateY(-10px);
    transition: all 0.3s ease-in-out;
    z-index: -1;
  }

  ul li:hover > div > div > ul,
  ul li:hover > div > ul,
  li:hover > ul {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  ul ul li {
    width: 170px;
    float: none;
    display: list-item;
    position: relative;
  }
  ul ul ul {
    top: 0;
    left: 170px;
  }
  ul ul ul li {
    position: relative;
    top: 0;
  }
  ul li ul li:hover,
  ul li ul li.open {
    background: rgb(18, 22, 39);
  }
  li > a:after {
    content: "arrow_drop_down";
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 25px;
    line-height: 1;
    margin-left: auto;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  li > a:only-child:after {
    content: "";
  }
  .top-search-box {
    color: $white;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 9;
    background-color: var(--bg-dark-blue);
    .search-box {
      outline: none;
      border: none;
      font-size: 1rem;
      height: calc(100% - 5px);
      background-color: var(--bg-dark-blue);
      color: $white;
      width: calc(100% - 50px);
    }
    .MuiInputBase-input {
      color: white !important;
    }
    .MuiInput-underline {
      &::before {
        border-bottom: 2px solid white !important;
      }
      &::after {
        border-bottom: 2px solid white !important;
      }
    }
  }
  .btn-white {
    color: $white;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
  .MuiBadge-colorSecondary {
    color: var(--text-body);
    background-color: var(--secondary);
  }
}

.NotificationsDrawer_drawer {
  inset: 0 0 0 auto !important;
  left: auto !important;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.19), 0 1px 6px rgba(0, 0, 0, 0.12);
  .MuiBackdrop-root {
    display: none;
  }
  .MuiDrawer-paper {
    box-shadow: var(--elevation-z8);
    bottom: 0;
    height: auto;
    width: 320px;
    overflow: inherit;
  }
  .frame_body {
    height: calc(100% - 75px);
    .MuiTab-fullWidth {
      min-width: auto;
    }
    .h-100 {
      .MuiBox-root {
        height: 100%;
      }
    }
  }
}

.notification {
  width: $sidenav-width;
  background-color: #eaf0f2;

  .notification__topbar {
    background-color: white;
    height: $topbar-height;
  }

  .notification__card {
    background-color: #eaf0f2;
    .card-blank {
      background: #eaf0f2;
      border-radius: 2px;
      position: relative;
      width: 320px;
    }
    .card-1 {
      box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 6px rgba(0, 0, 0, 0.12);
    }
    .card_data_font {
      color: #696e6f;
      font-size: 12px;
      font-weight: 700;
      padding: 10px;
    }
    .card {
      width: 320px;
      min-height: 80px;
      line-height: 20px;
      position: relative;
      background-color: #fff;
      border-top: 1px solid #dce3e6;
    }
    .card__topbar {
      padding: 10px;
    }
    .card_font {
      font-size: 11px;
      font-family: Roboto, sans-serif;
    }
    .card__topbar__button {
      height: 10px;
      width: 10px;
      padding: 21px;
      margin-top: 10px;
      -moz-border-radius: 70px;
      -webkit-border-radius: 70px;
      background-color: #eaf0f2;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.9;
      margin-left: 8px;
    }
    .card__topbar_text_ {
      padding: 10px;
      width: 40%;
    }
    .card__topbar__blue_dot {
      margin-bottom: -30px;
    }
    &:hover {
      .card__topbar__time {
        display: none;
        padding: 10px;
      }
      .card___Color {
        background-color: #dfe6f2;
      }
    }
    .MuiPaper-rounded {
      border-radius: 0px;
    }
  }
}
.simple-menu-drak {
  .MuiPopover-paper {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.06), 0px 8px 10px 1px rgba(0, 0, 0, 0.042),
      0px 3px 14px 2px rgba(0, 0, 0, 0.036);
    background-color: #222a45;
    ul {
      li {
        color: $white;
        min-height: auto;
        font-size: 14px;
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
}
.simple-menu-lite {
  .MuiPopover-paper {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.06), 0px 8px 10px 1px rgba(0, 0, 0, 0.042),
      0px 3px 14px 2px rgba(0, 0, 0, 0.036);
    background-color: #fff;
    ul {
      li {
        color: var(--text-body);
        min-height: auto;
        font-size: 14px;
        .material-icons {
          color: var(--text-muted);
        }
        &:hover {
          background-color: var(--primary);
          color: $white;
          .material-icons {
            color: $white !important;
          }
          svg {
            fill: $white;
          }
        }
      }
    }
  }
}
.selectedmainMenu {
  background-color: #ffffff29 !important;
  font-weight: 500;
}
