.profile_picture_div{
    display: flex;
}

.dropdown_div{
    margin-top: 30px;
    width: 250px;
}

.link_div{
    margin-top: 15px;
    font-size: 13px;
    font-weight: 400;
    color: #696E6F;
}

.outer_div{
    padding: 25px 30px;
    width: 100%;
}

.Typography{
    font-size: 13px !important;
    font-weight: 400 !important;
    color: #696E6F !important;
}

.input_label{
    font-size: 12px !important;
}

.Avatar{
    width: 80px !important;
    height: 80px !important;
}

.MenuItem{
    font-size: 13px !important;
    color: #0C0D0D !important ;
}

.grid_spacing{
    margin-top: 8px !important;
}

.top_typograpy{
    margin-bottom: 5px !important;
}

.UploadButton{
    //background-color: #2B41B2 !important ;
    color: #fff !important;
    font-size: 12px  !important;
    //cursor: pointer  !important;
    ////font-family: "Roboto,sans-serif";
    padding: 5px 16px  !important;
    //font-weight: 600  !important;
    margin: 0px 0 -120px -15px  !important;
    //position: relative  !important;
    //border-radius: 30px !important;
}

.UploadButton:hover{
    opacity: 0.9 !important;
}

.MenuItem:hover{
    background-color:#DCE3E6 !important ;
    color: #2B41B2 !important;
}

.tasks_div{
    margin-top: 30px !important;
}

.form_control_label{
    font-size: 12px !important;
}

.checkbox_labels{
    font-size: 12px !important;
}


