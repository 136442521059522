.center_bx{
    background: #fff;
    min-height: 500px;
    height: 525px;
    display: flex;
    width: 100%;
    box-shadow: 0 30px 60px 0 rgba(0,0,0,0.2);
    &.h-auto{
        min-height: auto;
        height: auto;
    }
    .layers_bg{
        background: url(/assets/images/posting_photo.svg) no-repeat #0069b0;
        background-size: 400px;
        background-position: center 50px;
        padding: 50px;
        h2{            
            color: $white;
            font-weight: 600;
        }
        p{            
            color: $white;
            font-size: 15px;
            line-height: 26px;
        }
    }
    .google_btn{
        width: 100%;
        background-color: var(--primary) !important;
        color:$white !important;
        box-shadow: none !important;
        border-radius: 4px !important;
        border: none !important;
        padding-left: 1px !important;
        div{
            padding:9px 10px 4px !important;
            border-radius: 4px 0 0 4px !important;
        }
        span{
            font-size: 0.875rem;
            font-weight: 400 !important;
            display: flex;
            width: 100%;
            justify-content: center;
        }
        &:hover{
            background-color: #5148a7 !important;
        }
    }
}
.user_info_modal{
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    h6 {
        font-size: 13px;
        margin: 0 0 5px;
    }

    p {
        color: var(--text-body);
        font-size: 13px;
        line-height: 18px;
        margin: 0;
    }
}
.w-350{
    .MuiDialog-paperWidthXs{
        max-width: 350px;
    }
}